import logo from '../jrgrouplogo.png';
import '../App.css';
import '../bootstrap.css'
import QRCode from "react-qr-code";
import {
    BrowserRouter,
    Routes,
    Route, useParams, Navigate, Link, useNavigate
} from "react-router-dom";
import {useEffect, useState} from "react";
import {supabase} from "../supabaseClient";
import Loading from './Loading';
import Logo from './Logo';

function VoucherHome(props) {
    const [allevents, setAllEvents] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedevent, setSelectedEvent] = useState("")
    const [voucherno, setVoucherNo] = useState("")


    const getEvents = async() => {
        const { data, error } = await supabase
            .from('get_public_events')
            .select()
        console.log(data)
        if (data) {
            setAllEvents(data)
            setSelectedEvent(data[0].event_name)
        }
    }

    useEffect(() => {
        setLoading(true) 
        getEvents()

        setLoading(false)
    }, [])

    console.log(selectedevent)

    if (loading) {
        // return (
        //     <div className={"d-flex justify-content-center flex-column"} style={{height: "100vh"}}>
        //         <div className="spinner-border" role="status" style={{margin: "0 auto"}}>
        //             <div className="sr-only"></div>
        //         </div>
        //     </div>
        // )
        // shifted into loading.jsx component
        return(
            <div>
                <Loading />
            </div>
            );
    } else {
        return (
            <div className="container d-flex flex-column py-5 text-center">
                <Logo />
                <div className="card mt-2 border-0 py-3 px-3 bg-light">
                    <div className={"card-body"}>
                        <h4>Enter your voucher number</h4>
                    </div>
                    <div className={"mt-2"}>
                        <input type="text" className="form-control" onChange={(e) => setVoucherNo(e.target.value)}/>
                    </div>
                    <div className={"mt-5"}>
                        <Link to={`voucher/${voucherno}`}>
                            <button type="button" className="px-5 py-3 btn btn-primary"
                                    style={{letterSpacing: "1px"}}>NEXT
                            </button>
                        </Link>
                    </div>
                </div>
                {/*<div className={"row mt-3 mb-3"}>
                    <div className={"col-5"}>
                        <hr/>
                    </div>
                    <div className={"col-2"}>
                        <span className={"align-middle"}>OR</span>
                    </div>
                    <div className={"col-5"}>
                        <hr/>
                    </div>
                </div>
                <div className="card mt-2 border-0 py-3 px-3 bg-light">
                    <div className={"card-body"}>
                        <h4>Sign up for an event</h4>
                    </div>
                    <div className={"mt-2 mb-2"}>
                        <select className="form-select" value={selectedevent} onChange={(e) => setSelectedEvent(e.target.value)}>
                            {
                                allevents.map(each => <option value={each.event_name}>{each.event_name}</option>)
                            }
                        </select>
                    </div>
                    <div className={"mt-5"}>
                        <Link to={`event/${selectedevent}`}>
                            <button type="button" className="px-5 py-3 btn btn-primary"
                                    style={{letterSpacing: "1px"}}>NEXT
                            </button>
                        </Link>
                    </div>
                </div>*/}
            </div>
        )
    }
}

export default VoucherHome;