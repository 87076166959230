import logo from '../jrgrouplogo.png';
import '../App.css';
import '../bootstrap.css'
import QRCode from "react-qr-code";
import {
    BrowserRouter,
    Routes,
    Route, useParams, Navigate, Link, useNavigate
} from "react-router-dom";
import {useEffect, useState} from "react";
import {supabase} from "../supabaseClient";
import Loading from './Loading';
import Logo from "./Logo";
import '../styles/react-phone-number-input.css';
import PhoneInput from 'react-phone-number-input'
import axios from "axios";
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp } from 'firebase/app';
const fbapp = initializeApp({
    apiKey: "AIzaSyC9OmqZwgB9QUERICGe-2PotxIfXOlRkdU",
    authDomain: "jr-ubs-cafe.firebaseapp.com",
    projectId: "jr-ubs-cafe",
    storageBucket: "jr-ubs-cafe.appspot.com",
    messagingSenderId: "364797247416",
    appId: "1:364797247416:web:e678e3c215102a444f194d"
});
const functions = getFunctions(fbapp);
const sendVoucher = httpsCallable(functions, 'sendVoucher');
const sendOTP = httpsCallable(functions, 'sendOTP');
const verifyOTP = httpsCallable(functions, 'verifyOTP');


function EventHome() {
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState("")
    const [GPin, setGPin] = useState("")
    const [smsOTP, setSmsOTP] = useState("")

    const [email, setEmail] = useState("")
    const [err, setErr] = useState("")
    const [infoText, setInfoText] = useState("")
    const [isvalid, setIsValid] = useState(false)
    const [isInternal, setIsInternal] = useState(false)
    const [eventname, setEventName] = useState("")

    // States to change "hidden" properties
    const [signUpHidden, setSignUpHidden] = useState(true) // set signup button to be hidden at start
    const [loadingHidden, setLoadingHidden] = useState(true) // set loading button to be hidden at start                    
    const [OTPInputHidden, setOTPInputHidden] = useState(true) // set OTP input box to be hidden at start
    const [sendOTPButtonHidden, setSendOTPButtonHidden] = useState(false) // show send OTP button at start to initiate signup process
    const [verifyOTPButtonHidden, setVerifyOTPButtonHidden] = useState(true)

    const params = useParams()
    const navigate = useNavigate()
    // useState using phone number input library
    // `value` will be the parsed phone number in E.164 format.
    // Example: "+12133734253".
    const [phoneNumber, setPhoneNumber] = useState()

    // functions
    const sendOTPSms = async() => {
        setSendOTPButtonHidden(true); // hide send OTP button when request for OTP
        setLoadingHidden(false);      // show loading button while pending status   
        // console.log("OTP Sent");
        //send OTP API call
        sendOTP({ to: phoneNumber})
            .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */            
            console.log("sending OTP");
            //console.log(result);
            //const dataStatus = data.checkStatus;
           // console.log(data);
            const data = result.data;
            const dataStatus = data.status;
            console.log(dataStatus);
            if (dataStatus === "success") {
                console.log("OTP sent")
                setLoadingHidden(true); // hide loading button
                setOTPInputHidden(false); // show verify OTP input box   
                setVerifyOTPButtonHidden(false);  // show verify OTP button              
            } else {
                console.log("OTP not sent");
                setLoadingHidden(true); // hide loading button
                setSendOTPButtonHidden(false); // show back send OTP button
            }
            // console.log(data.result);
            // return("okay")
            // navigate(`/success-send-voucher`)
        })
        .catch((err) => {
            console.log("The error is: "+err)
            // return ("error")
        })
    }

    const verifyOTPSms = async() => {
        setErr("");                     // reset the err div to be empty
        setVerifyOTPButtonHidden(true); // hide verify OTP button when submit OTP for verification
        setLoadingHidden(false);        // show loading button while verification in progress
        verifyOTP({ to: phoneNumber, otp: smsOTP})
        .then((result) => {
            // Read result of the Cloud Function.
            ///** @type {any} */
            // console.log("result");
            // console.log(result);
            // console.log("data");
            // console.log(data);
            // console.log("data.status");
            // console.log(data.status);
            // console.log("data.result");
            // console.log(data.result);
            console.log("verifying OTP");
            const data = result.data;
            const verificationStatus = data.status;
            console.log(verificationStatus);
            if (verificationStatus === "success") {
                console.log("OTP verified 'Satis'")
                assignVoucher()
                setInfoText("OTP verified! Please wait while we redirect you to your voucher"); // display text below button to inform user to signup
                setLoadingHidden(true); // hide loading button
            } else if (verificationStatus === "wrongOTP") {
                console.log("Wrong OTP!")
                setLoadingHidden(true); // hide loading button
                setVerifyOTPButtonHidden(false); // show back verify OTP button 
                setErr("⚠ Wrong OTP. Please enter the correct OTP.");   
            } else {
                setErr("⚠ There is an error. Please contact admin.");
            }
            //console.log("Status inside is:" + verificationStatus);
            // add the check verification flow inside here -> if (data.result === approved) {do something}, etc.
        })
        // .then(verification_check => console.log(verification_check.status))
        .catch((err) => {
            console.log("The error is: "+err)
            // return ("error")
        })
        //alert("verification good"); 
    }


    const assignVoucher = async() => {
        setSignUpHidden(true);
        setLoadingHidden(false);  
        const { data, error } = await supabase
            .from('vouchers')
            .update({ assigned_name: name, assigned_email: email, is_assigned: true, gpin: GPin, mobile_number: phoneNumber }) //update Name, gpin and mobile_number columns in supabase
            .order('id')
            .limit(1)
            .is('is_public', true)
            .is("is_assigned", false)
            .eq("event_id", params.eventId)
        if (data) {
            sendVoucher({ to: phoneNumber, short_id: data[0]["short_id"]})
                .then((result) => {
                    navigate(`/voucher/${data[0]["short_id"]}`)
                })
                .catch((err) => {
                    console.log(err)
                })
            {/*
            navigate(`/success-send-voucher`)
            axios.post('https://eowb1n866weulln.m.pipedream.net', {
                to: phoneNumber,
                short_id: data[0]["short_id"]
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
             */}
        } else {
            setErr("⚠ No more vouchers available")
        }
        console.log(error)
    }

    const checkEvent = async() => {
        setLoading(true)
        const { data, error } = await supabase
            .from('vouchers')
            .select()
            .limit(1)
            .is('is_public', true)
            .is("is_assigned", false)
            .eq("event_id", params.eventId)
        console.log(data)
    //    console.log(data[0]["is_internal"])
        // if (data && data[0]["is_internal"] && data.length > 0) {
        //     setEventName(data[0]["event_name"])
        //     setIsInternal(true)
        //     setIsValid(true)
        //     setLoading(false)
        // } else if(data && data.length > 0 ) {
        //     setEventName(data[0]["event_name"])
        //     setIsValid(true)
        //     setLoading(false)
        // }
        // else {
        //     setIsValid(false)
        //     setLoading(false)
        // }
        if (data && data.length > 0) {
            if (data[0]["is_internal"])
            {
            setEventName(data[0]["event_name"])
            setIsInternal(true)
            setIsValid(true)
            setLoading(false)
            }
            else {
            setEventName(data[0]["event_name"])
            setIsValid(true)
            setLoading(false)
            }
        } 
        else {
            setIsValid(false)
            setLoading(false)
        }
        console.log(error)
    }

    useEffect(() => {
        checkEvent()
    }, [])

    if (loading) {
        return (
            // <div className={"d-flex justify-content-center flex-column"} style={{height: "100vh"}}>
            //     <div className="spinner-border" role="status" style={{margin: "0 auto"}}>
            //         <div className="sr-only"></div>
            //     </div>
            // </div>
            <Loading />
        )
    } else {
        return (
            <div className="container d-flex flex-column py-5 text-center">
                <Logo />
                {isvalid
                    ?
                    isInternal 
                        ?
                        <div className="card mt-2 border-0 py-3 px-3 bg-light">
                        {/* Signup page for internal event requiring GPin */}
                            <div className={"card-body"}>
                                <h4>Sign up for {eventname}</h4>
                            </div>
                            <div className={"mt-2 text-start"}>
                                <label className={"form-label"}>Name</label>
                                <input id="eventsignupname" type="text" className="form-control" value={name}
                                    onChange={(e) => setName(e.target.value)}/>
                            </div>
                            <div className={"mt-2 text-start"}>
                                <label className={"form-label"}>GPin</label>
                                <input id="eventsignupgpin" type="number" className="form-control" minLength={8} maxLength={8} value={GPin} //need another form of validation of length
                                    onChange={(e) => setGPin(e.target.value.slice(0,8))}/>
                            </div>
                            {/* use phone number input library */}
                            <div className={"mt-2 text-start"}>
                                <label className={"form-label"}>Mobile Number</label>
                                <PhoneInput
                                    defaultCountry='SG'
                                    placeholder="Enter phone number"
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                />
                            </div>
                            {/* Input box for verify OTP */}
                            <div hidden= {OTPInputHidden} className={"mt-2 text-start"}>
                                <label className={"form-label"}>Enter your OTP</label>
                                <input id="otp" type="number" className="form-control" value={smsOTP} placeholder="123456" 
                                    onChange={(e) => setSmsOTP(e.target.value.slice(0,6))}/>
                            </div>
                            {/* set info text */}
                            <div className={"mt-3 text-success"}>
                                {infoText}
                            </div>
                            {/* set err text */}
                            <div className={"mt-3 text-danger"}>
                                {err}
                            </div>
                            {/* Buttons */}
                            <div className={"mt-3"}>
                                {/* Complete signup and send SMS with Voucher link button */}
                                {/* App can redirect to voucher after verifying OTP without sending link 
                                <button type="button" className="px-5 py-3 btn btn-primary"
                                        style={{letterSpacing: "1px"}} onClick={() => assignVoucher()} disabled={!phoneNumber || !GPin || !name} hidden= {signUpHidden} >SIGN UP
                                </button>
                                /*}
                                {/* Loading button */}
                                <button className="px-5 py-3 btn btn-primary" type="button" disabled hidden= {loadingHidden}>
                                    <div className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
                                </button>
                                {/* Send OTP button */}
                                <button className="px-5 py-3 btn btn-primary" type="button" disabled={!phoneNumber || !GPin || !name} hidden= {sendOTPButtonHidden} 
                                    onClick={() => sendOTPSms()}>
                                    SEND OTP
                                </button>
                                {/* Verify OTP button */}
                                <button className="px-5 py-3 btn btn-primary" type="button" disabled={!phoneNumber || !GPin || !name || !smsOTP} hidden= {verifyOTPButtonHidden}
                                    onClick={() => verifyOTPSms()}>
                                    VERIFY OTP
                                </button>
                            </div>
                        </div>
                        :
                        <div className="card mt-2 border-0 py-3 px-3 bg-light">
                        {/* Removed GPin for non-internal event */}
                            <div className={"card-body"}>
                                <h4>Sign up for {eventname}</h4>
                            </div>
                            <div className={"mt-2 text-start"}>
                                <label className={"form-label"}>Name</label>
                                <input id="eventsignupname" type="text" className="form-control" value={name}
                                    onChange={(e) => setName(e.target.value)}/>
                            </div>
                            {/* <div className={"mt-2 text-start"}>
                                <label className={"form-label"}>GPin</label>
                                <input id="eventsignupgpin" type="number" className="form-control" minLength={8} maxLength={8} value={GPin} //need another form of validation of length
                                    onChange={(e) => setGPin(e.target.value.slice(0,8))}/>
                            </div> */}
                            {/* use phone number input library */}
                            <div className={"mt-2 text-start"}>
                                <label className={"form-label"}>Mobile Number</label>
                                <PhoneInput
                                    defaultCountry='SG'
                                    placeholder="Enter phone number"
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                />
                            </div>
                            {/* Input box for verify OTP */}
                            <div hidden= {OTPInputHidden} className={"mt-2 text-start"}>
                                <label className={"form-label"}>Enter your OTP</label>
                                <input id="otp" type="number" className="form-control" value={smsOTP} placeholder="123456" 
                                    onChange={(e) => setSmsOTP(e.target.value.slice(0,6))}/>
                            </div>
                            {/* set info text */}
                            <div className={"mt-3 text-success"}>
                                {infoText}
                            </div>
                            {/* set err text */}
                            <div className={"mt-3 text-danger"}>
                                {err}
                            </div>
                            {/* Buttons */}
                            <div className={"mt-3"}>
                                {/* Complete signup and send SMS with Voucher link button */}
                                {/* App can redirect to voucher after verifying OTP without sending link 
                                <button type="button" className="px-5 py-3 btn btn-primary"
                                        style={{letterSpacing: "1px"}} onClick={() => assignVoucher()} disabled={!phoneNumber || !GPin || !name} hidden= {signUpHidden} >SIGN UP
                                </button>
                                /*}
                                {/* Loading button */}
                                <button className="px-5 py-3 btn btn-primary" type="button" disabled hidden= {loadingHidden}>
                                    <div className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
                                </button>
                                {/* Send OTP button */}
                                <button className="px-5 py-3 btn btn-primary" type="button" disabled={!phoneNumber || !name} hidden= {sendOTPButtonHidden} 
                                    onClick={() => sendOTPSms()}>
                                    SEND OTP
                                </button>
                                {/* Verify OTP button */}
                                <button className="px-5 py-3 btn btn-primary" type="button" disabled={!phoneNumber || !name || !smsOTP} hidden= {verifyOTPButtonHidden}
                                    onClick={() => verifyOTPSms()}>
                                    VERIFY OTP
                                </button>
                            </div>
                        </div>
                    :
                    <div className="card mt-2 border-0 py-3 px-3" style={{backgroundColor: "#FFEBEB"}}>
                        <div className={"card-body text-danger"}>
                            <h4>We have ran out of vouchers for this event</h4>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default EventHome;
