import logo from './jrgrouplogo.png';
import './App.css';
import './bootstrap.css'
import QRCode from "react-qr-code";
import {
    BrowserRouter,
    Routes,
    Route, useParams, Navigate, Link, useNavigate
} from "react-router-dom";
import {useEffect, useState} from "react";
import {supabase} from "./supabaseClient";
import Loading from "./components/Loading";
import VoucherHome from './components/VoucherHome';
import EventHome from './components/EventHome';
import VoucherMain from './components/VoucherMain';
import VoucherSendSuccess from "./components/VoucherSendSuccess";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<VoucherHome/>}/>
              <Route path="/event/:eventId" element={<EventHome/>} />
              <Route path="/voucher/:voucherId" element={<VoucherMain />} />
              <Route path="/success-send-voucher" element={<VoucherSendSuccess/>}/>
          </Routes>
      </BrowserRouter>
  );
}

// Shifted to EventHome.jsx & VoucherHome.jsx components respectively
// function EventHome() {
//     const [loading, setLoading] = useState(false)
//     const [name, setName] = useState("")
//     const [email, setEmail] = useState("")
//     const [err, setErr] = useState("")
//     const [isvalid, setIsValid] = useState(false)
//     const [eventname, setEventName] = useState("")
//     const params = useParams()
//     const navigate = useNavigate();

//     const assignVoucher = async() => {
//         const { data, error } = await supabase
//             .from('vouchers')
//             .update({ assigned_name: name, assigned_email: email, is_assigned: true })
//             .order('id')
//             .limit(1)
//             .is('is_public', true)
//             .is("is_assigned", false)
//             .eq("event_id", params.eventId)
//         if (data) {
//             navigate(`/voucher/${data[0]["short_id"]}`)
//         } else {
//             setErr("⚠ No more vouchers available")
//         }
//         console.log(error)
//     }

//     const checkEvent = async() => {
//         setLoading(true)
//         const { data, error } = await supabase
//             .from('vouchers')
//             .select()
//             .limit(1)
//             .is('is_public', true)
//             .is("is_assigned", false)
//             .eq("event_id", params.eventId)
//         console.log(data)
//         if (data && data.length > 0) {
//             setEventName(data[0]["event_name"])
//             setIsValid(true)
//             setLoading(false)
//         } else {
//             setIsValid(false)
//             setLoading(false)
//         }
//         console.log(error)
//     }

//     useEffect(() => {
//         checkEvent()
//     }, [])

//     if (loading) {
//         return (
//             <div className={"d-flex justify-content-center flex-column"} style={{height: "100vh"}}>
//                 <div className="spinner-border" role="status" style={{margin: "0 auto"}}>
//                     <div className="sr-only"></div>
//                 </div>
//             </div>
//         )
//     } else {
//         return (
//             <div className="container d-flex flex-column py-5 text-center">
//                 <div className="text-center">
//                     <img src={logo} alt="logo" style={{margin: "0 auto", "width": "80px"}}/>
//                 </div>
//                 {isvalid
//                     ?
//                     <div className="card mt-2 border-0 py-3 px-3 bg-light">
//                         <div className={"card-body"}>
//                             <h4>Sign up for {eventname}</h4>
//                         </div>
//                         <div className={"mt-2 text-start"}>
//                             <label className={"form-label"}>Name</label>
//                             <input id="eventsignupname" type="text" className="form-control" value={name}
//                                    onChange={(e) => setName(e.target.value)}/>
//                         </div>
//                         <div className={"mt-2 text-start"}>
//                             <label className={"form-label"}>Email</label>
//                             <input id="eventsignupemail" type="text" className="form-control" value={email}
//                                    onChange={(e) => setEmail(e.target.value)}/>
//                         </div>
//                         <div className={"mt-5"}>
//                             <button type="button" className="px-5 py-3 btn btn-primary"
//                                     style={{letterSpacing: "1px"}} onClick={() => assignVoucher()}>SIGN UP
//                             </button>
//                         </div>
//                         <div className={"text-danger"}>
//                             {err}
//                         </div>
//                     </div>
//                     :
//                     <div className="card mt-2 border-0 py-3 px-3" style={{backgroundColor: "#FFEBEB"}}>
//                         <div className={"card-body text-danger"}>
//                             <h4>We have ran out of vouchers for this event</h4>
//                         </div>
//                     </div>
//                 }
//             </div>
//         )
//     }
// }


//shifted VoucherHome into VoucherHome.jsx
// function VoucherHome() {
//     const [allevents, setAllEvents] = useState([])
//     const [loading, setLoading] = useState(false)
//     const [selectedevent, setSelectedEvent] = useState("")
//     const [voucherno, setVoucherNo] = useState("")


//     const getEvents = async() => {
//         const { data, error } = await supabase
//             .from('get_public_events')
//             .select()
//         console.log(data)
//         if (data) {
//             setAllEvents(data)
//             setSelectedEvent(data[0].event_name)
//         }
//     }

//     useEffect(() => {
//         setLoading(true)
//         getEvents()

//         setLoading(false)
//     }, [])

//     console.log(selectedevent)

//     if (loading) {
//         return (
//             <div className={"d-flex justify-content-center flex-column"} style={{height: "100vh"}}>
//                 <div className="spinner-border" role="status" style={{margin: "0 auto"}}>
//                     <div className="sr-only"></div>
//                 </div>
//             </div>
//         )
//     } else {
//         return (
//             <div className="container d-flex flex-column py-5 text-center">
//                 <div className="text-center">
//                     <img src={logo} alt="logo" style={{margin: "0 auto", "width": "80px"}}/>
//                 </div>
//                 <div className="card mt-2 border-0 py-3 px-3 bg-light">
//                     <div className={"card-body"}>
//                         <h4>Enter your voucher number</h4>
//                     </div>
//                     <div className={"mt-2"}>
//                         <input type="text" className="form-control" onChange={(e) => setVoucherNo(e.target.value)}/>
//                     </div>
//                     <div className={"mt-5"}>
//                         <Link to={`voucher/${voucherno}`}>
//                             <button type="button" className="px-5 py-3 btn btn-primary"
//                                     style={{letterSpacing: "1px"}}>NEXT
//                             </button>
//                         </Link>
//                     </div>
//                 </div>
//                 {/*<div className={"row mt-3 mb-3"}>
//                     <div className={"col-5"}>
//                         <hr/>
//                     </div>
//                     <div className={"col-2"}>
//                         <span className={"align-middle"}>OR</span>
//                     </div>
//                     <div className={"col-5"}>
//                         <hr/>
//                     </div>
//                 </div>
//                 <div className="card mt-2 border-0 py-3 px-3 bg-light">
//                     <div className={"card-body"}>
//                         <h4>Sign up for an event</h4>
//                     </div>
//                     <div className={"mt-2 mb-2"}>
//                         <select className="form-select" value={selectedevent} onChange={(e) => setSelectedEvent(e.target.value)}>
//                             {
//                                 allevents.map(each => <option value={each.event_name}>{each.event_name}</option>)
//                             }
//                         </select>
//                     </div>
//                     <div className={"mt-5"}>
//                         <Link to={`event/${selectedevent}`}>
//                             <button type="button" className="px-5 py-3 btn btn-primary"
//                                     style={{letterSpacing: "1px"}}>NEXT
//                             </button>
//                         </Link>
//                     </div>
//                 </div>*/}
//             </div>
//         )
//     }
// }

//shifted to VoucherMain.jsx component
// function VoucherMain() {
//     const params = useParams()
//     const [loading, setLoading] = useState(false)
//     const [voucherstate, setVoucherState] = useState("")
//     const [voucherdata, setVoucherData] = useState("")
//     const [showRedeem, setShowRedeem] = useState(false)

//     useEffect(() => {
//         checkVoucher()

//         const voucherSub = supabase
//             .from(`vouchers:short_id=eq.${params.voucherId}`)
//             .on('*', checkVoucher)
//             .subscribe()

//         async function removeSubscription() {
//             await supabase.removeSubscription(voucherSub)
//         }
//         return () => {
//             removeSubscription()
//         }
//     }, [])

//     const showRedeemButton = () => {
//         setShowRedeem(true)
//     }

//     const checkVoucher = async() => {
//         setLoading(true)
//         const { data, error } = await supabase
//             .from('vouchers')
//             .select()
//             .eq('short_id', params.voucherId)
//         console.log(data)
//         if (data.length > 0) {
//             if (data[0]["redeem_at"]) {
//                 setLoading(false)
//                 setVoucherData(data)
//                 setVoucherState("redeemed")
//             } else if(new Date(data[0]["valid_until"]) < new Date()) {
//                 setLoading(false)
//                 setVoucherData(data)
//                 setVoucherState("expired")
//             } else {
//                 setLoading(false)
//                 setVoucherData(data)
//                 setVoucherState("valid")
//             }
//         } else {
//             setLoading(true) //change this
//             setVoucherState("invalid")
//         }
//     }

//     const updateRedeem = async() => {
//         setLoading(true)
//         const {data, error} = await supabase
//             .from("vouchers")
//             .update({redeem_at: new Date()})
//             .eq("short_id", params.voucherId)
//         if (data) {
//             setLoading(false)
//             setVoucherData(data)
//             setVoucherState("redeemed")
//         }
//     }

//     if (loading) {
//         // return (
//         //     <div className={"d-flex justify-content-center flex-column"} style={{height: "100vh"}}>
//         //         <div className="spinner-border" role="status" style={{margin: "0 auto"}}>
//         //             <div className="sr-only"></div>
//         //         </div>
//         //     </div>
//         // )
//         <Loading />
//     } else if (voucherstate === "valid") {
//         return (
//             <div className="container-fluid d-flex flex-column py-5 text-center">
//                 <div className="text-center">
//                     <img src={logo} alt="logo" style={{margin: "0 auto", "width": "80px"}}/>
//                 </div>
//                 <div className="my-1 text-uppercase">UBS Cafe</div>
//                 {
//                     showRedeem
//                         ?
//                         <div className="card mt-2 border-0 py-3 bg-light">
//                             <div className={"card-body"}>
//                                 <h1>Terms and Conditions</h1>
//                                 <ul>
//                                     <li className={"mb-5 text-start"}>This deal is only valid if you click on the Redeem Now button in the presence of a UBS Cafe staff.</li>
//                                 </ul>
//                                 <div className={"mt-5"}>
//                                     <button onClick={updateRedeem} type="button" className="px-5 py-3 btn btn-primary" style={{letterSpacing: "1px"}}>CONFIRM</button>
//                                 </div>
//                                 <div className={"mt-1"}>
//                                     <button onClick={() => setShowRedeem(false)} type="button" className="px-5 py-3 btn" style={{letterSpacing: "1px"}}>← Back to voucher</button>
//                                 </div>
//                             </div>
//                         </div>
//                         :
//                         <div>
//                         <div className="card mt-2 border-0 py-3">
//                             <div className={"card-body"}>
//                                 <h6 className={"text-uppercase"}>{voucherdata[0].event_name}</h6>
//                                 <h1>{voucherdata[0].desc}</h1>
//                                 <div className={"mb-3"}>Voucher ID: {voucherdata[0].voucherId}</div>
//                                 <div className={"mb-5"}>Valid until: {new Date(voucherdata[0].valid_until).toLocaleDateString('fr-ca')} {new Date(voucherdata[0].valid_until).toLocaleTimeString('en-US')}</div>
//                                 {/*<div style={{background: 'white', padding: '24px'}}>
//                                     <QRCode value={voucherdata[0].voucherId} style={{margin: "0 auto"}}/>
//                                 </div>*/}
//                             </div>
//                         </div>
//                             <div className={"mt-5"}>
//                                 <button onClick={showRedeemButton} type="button" className="px-5 py-3 btn btn-primary" style={{letterSpacing: "1px"}}>REDEEM DEAL</button>
//                             </div>
//                         </div>
//                 }


//             </div>
//         )
//     } else if (voucherstate === "redeemed") {
//         return (
//             <div className="container-fluid d-flex flex-column py-3 text-center">
//                 <div className="text-center">
//                     <img src={logo} alt="logo" style={{margin: "0 auto", "width": "80px"}}/>
//                 </div>
//                 <div className="my-1 text-uppercase">UBS Cafe</div>
//                 <div className="card mt-2 border-0" style={{backgroundColor: "#DAFBCE"}}>
//                     <div className={"card-body py-5"}>
//                         <h6 className={"text-uppercase text-success"}>{voucherdata[0].event_name}</h6>
//                         <h1 className={"text-success"}>{voucherdata[0].desc}</h1>
//                         <div className={"mb-3 text-success"}>Voucher ID: {voucherdata[0].voucherId}</div>
//                         <div className={"text-success mb-5"}>Redeemed at: {new Date(voucherdata[0].redeem_at).toLocaleDateString('fr-ca')} {new Date(voucherdata[0].redeem_at).toLocaleTimeString('en-US')}</div>
//                         <div style={{background: 'white', padding: '24px'}}>
//                             <QRCode value={voucherdata[0].voucherId} style={{margin: "0 auto"}}/>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     } else if (voucherstate === "expired") {
//         return (
//             <div className="container-fluid d-flex flex-column py-3 text-center">
//                 <div className="text-center">
//                     <img src={logo} alt="logo" style={{margin: "0 auto", "width": "80px"}}/>
//                 </div>
//                 <div className="my-1 text-uppercase">UBS Cafe</div>
//                 <div className="card mt-2 border-0" style={{backgroundColor: "#FFEBEB"}}>
//                     <div className={"card-body py-5"}>
//                         <h1 className={"text-danger"}>Your Voucher Has Expired</h1>
//                         <div className={"mb-5"}>Voucher ID: {voucherdata[0].voucherId}</div>
//                         <div className={"text-danger mb-5"}>Expired at: {new Date(voucherdata[0].valid_until).toLocaleDateString('fr-ca')} {new Date(voucherdata[0].valid_until).toLocaleTimeString('en-US')}</div>
//                         <div style={{background: 'white', padding: '24px'}}>
//                             <QRCode value={voucherdata[0].voucherId} style={{margin: "0 auto"}}/>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     } else {
//         return (
//             <div className="container-fluid d-flex flex-column py-5 text-center">
//                 <div className="text-center">
//                     <img src={logo} alt="logo" style={{margin: "0 auto", "width": "80px"}}/>
//                 </div>
//                 <div className="my-1 text-uppercase">UBS Cafe</div>
//                 <div className="card mt-2 border-0" style={{backgroundColor: "#FFEBEB"}}>
//                     <div className={"card-body py-5"}>
//                         <h1 className={"text-danger"}>Invalid Voucher</h1>
//                         <div>Please contact your UBS representative or speak to one of the Cafe staff</div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }

// }

export default App;
