import logo from '../jrgrouplogo.png';
import '../App.css';
import '../bootstrap.css'
import QRCode from "react-qr-code";
import {
    BrowserRouter,
    Routes,
    Route, useParams, Navigate, Link, useNavigate
} from "react-router-dom";
import {useEffect, useState} from "react";
import {supabase} from "../supabaseClient";

function Logo(){
    const navigate = useNavigate();
    return (
        <div className="text-center">
            
            <img onClick={()=> navigate(-1)} src={logo} alt="logo" style={{margin: "0 auto", "width": "80px"}}/>
            
            {/* <Link to={`/`}>
                <img src={logo} alt="logo" style={{margin: "0 auto", "width": "80px"}}/>
            </Link> */}
        </div>
    );
}

export default Logo;