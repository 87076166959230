import logo from '../jrgrouplogo.png';
import '../App.css';
import '../bootstrap.css'
import QRCode from "react-qr-code";
import {
    BrowserRouter,
    Routes,
    Route, useParams, Navigate, Link, useNavigate
} from "react-router-dom";
import {useEffect, useState} from "react";
import {supabase} from "../supabaseClient";


function Loading(){
    return (
        <div className={"d-flex justify-content-center flex-column"} style={{height: "100vh"}}>
            <div className="spinner-border" role="status" style={{margin: "0 auto"}}>
                <div className="sr-only"></div>
            </div>
        </div>
    )
}

export default Loading;