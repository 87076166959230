import logo from '../jrgrouplogo.png';
import '../App.css';
import '../bootstrap.css'
import QRCode from "react-qr-code";
import {
    BrowserRouter,
    Routes,
    Route, useParams, Navigate, Link, useNavigate
} from "react-router-dom";
import {useEffect, useState} from "react";
import {supabase} from "../supabaseClient";
import Loading from './Loading';
import Logo from "./Logo";

function VoucherMain() {
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [voucherstate, setVoucherState] = useState("")
    const [voucherdata, setVoucherData] = useState("")
    const [showRedeem, setShowRedeem] = useState(false)

    useEffect(() => {
        checkVoucher()

        const voucherSub = supabase
            .from(`vouchers:short_id=eq.${params.voucherId}`)
            .on('*', checkVoucher)
            .subscribe()

        async function removeSubscription() {
            await supabase.removeSubscription(voucherSub)
        }
        return () => {
            removeSubscription()
        }
    }, [])

    const showRedeemButton = () => {
        setShowRedeem(true)
    }

    const checkVoucher = async() => {
        setLoading(true)
        const { data, error } = await supabase
            .from('vouchers')
            .select()
            .eq('short_id', params.voucherId)
        console.log(data)
        if (data.length > 0) {
            if (data[0]["redeem_at"]) {
                setLoading(false)
                setVoucherData(data)
                setVoucherState("redeemed")
            } else if(new Date(data[0]["valid_until"]) < new Date()) {
                setLoading(false)
                setVoucherData(data)
                setVoucherState("expired")
            } else {
                setLoading(false)
                setVoucherData(data)
                setVoucherState("valid")
            }
        } else {
            setLoading(false) 
            setVoucherState("invalid")
        }
    }

    const updateRedeem = async() => {
        setLoading(true)
        const {data, error} = await supabase
            .from("vouchers")
            .update({redeem_at: new Date()})
            .eq("short_id", params.voucherId)
        if (data) {
            setLoading(false)
            setVoucherData(data)
            setVoucherState("redeemed")
        }
    }

    if (loading) {
        return (
            <Loading />
        )
        
    } else if (voucherstate === "valid") {
        return (
            <div className="container-fluid d-flex flex-column py-5 text-center">
                <Logo />
                <div className="my-1 text-uppercase">UBS Cafe</div>
                {
                    showRedeem
                        ?
                        <div className="card mt-2 border-0 py-3 bg-light">
                            <div className={"card-body"}>
                                <h1>Terms and Conditions</h1>
                                <ul>
                                    <li className={"mb-5 text-start"}>This deal is only valid if you click on the Redeem Now button in the presence of a UBS Cafe staff.</li>
                                </ul>
                                <div className={"mt-5"}>
                                    <button onClick={updateRedeem} type="button" className="px-5 py-3 btn btn-primary" style={{letterSpacing: "1px"}}>CONFIRM</button>
                                </div>
                                <div className={"mt-1"}>
                                    <button onClick={() => setShowRedeem(false)} type="button" className="px-5 py-3 btn" style={{letterSpacing: "1px"}}>← Back to voucher</button>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                        <div className="card mt-2 border-0 py-3">
                            <div className={"card-body"}>
                                <h6 className={"text-uppercase"}>{voucherdata[0].event_name}</h6>
                                <h1>{voucherdata[0].desc}</h1>
                                <div className={"mb-3"}>Voucher ID: {voucherdata[0].voucherId}</div>
                                {
                                    voucherdata[0].valid_from
                                    ?
                                    <div className={"mb-3"}>Valid from: {new Date(voucherdata[0].valid_from).toLocaleDateString('fr-ca')} {new Date(voucherdata[0].valid_from).toLocaleTimeString('en-US')}</div>
                                    :
                                    null
                                }
                                <div className={"mb-5"}>Valid until: {new Date(voucherdata[0].valid_until).toLocaleDateString('fr-ca')} {new Date(voucherdata[0].valid_until).toLocaleTimeString('en-US')}</div>
                                {/*<div style={{background: 'white', padding: '24px'}}>
                                    <QRCode value={voucherdata[0].voucherId} style={{margin: "0 auto"}}/>
                                </div>*/}
                            </div>
                        </div>
                            <div className={"mt-5"}>
                                <button onClick={showRedeemButton} type="button" className="px-5 py-3 btn btn-primary" style={{letterSpacing: "1px"}}>REDEEM DEAL</button>
                            </div>
                        </div>
                }


            </div>
        )
    } else if (voucherstate === "redeemed") {
        return (
            <div className="container-fluid d-flex flex-column py-3 text-center">
                {/* <div className="text-center">
                    <img src={logo} alt="logo" style={{margin: "0 auto", "width": "80px"}}/>
                </div> */}
                <Logo />
                <div className="my-1 text-uppercase">UBS Cafe</div>
                <div className="card mt-2 border-0" style={{backgroundColor: "#DAFBCE"}}>
                    <div className={"card-body py-5"}>
                        <h6 className={"text-uppercase text-success"}>{voucherdata[0].event_name}</h6>
                        <h1 className={"text-success"}>{voucherdata[0].desc}</h1>
                        <div className={"mb-3 text-success"}>Voucher ID: {voucherdata[0].voucherId}</div>
                        <div className={"text-success mb-5"}>Redeemed at: {new Date(voucherdata[0].redeem_at).toLocaleDateString('fr-ca')} {new Date(voucherdata[0].redeem_at).toLocaleTimeString('en-US')}</div>
                        <div style={{background: 'white', padding: '24px'}}>
                            <QRCode value={voucherdata[0].voucherId} style={{margin: "0 auto"}}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (voucherstate === "expired") {
        return (
            <div className="container-fluid d-flex flex-column py-3 text-center">
                <Logo />
                <div className="my-1 text-uppercase">UBS Cafe</div>
                <div className="card mt-2 border-0" style={{backgroundColor: "#FFEBEB"}}>
                    <div className={"card-body py-5"}>
                        <h1 className={"text-danger"}>Your Voucher Has Expired</h1>
                        <div className={"mb-5"}>Voucher ID: {voucherdata[0].voucherId}</div>
                        <div className={"text-danger mb-5"}>Expired at: {new Date(voucherdata[0].valid_until).toLocaleDateString('fr-ca')} {new Date(voucherdata[0].valid_until).toLocaleTimeString('en-US')}</div>
                        <div style={{background: 'white', padding: '24px'}}>
                            <QRCode value={voucherdata[0].voucherId} style={{margin: "0 auto"}}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="container-fluid d-flex flex-column py-5 text-center">
                <Logo />
                <div className="my-1 text-uppercase">UBS Cafe</div>
                <div className="card mt-2 border-0" style={{backgroundColor: "#FFEBEB"}}>
                    <div className={"card-body py-5"}>
                        <h1 className={"text-danger"}>Invalid Voucher</h1>
                        <div>Please contact your UBS representative or speak to one of the Cafe staff</div>
                    </div>
                </div>
            </div>
        )
    }

}

export default VoucherMain;