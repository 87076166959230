import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import Logo from "./Logo";

export default function VoucherSendSuccess() {
    const navigate = useNavigate();

    return (
        <div className="container-fluid d-flex flex-column py-3 text-center">
            <Logo />
            <div className="my-1 text-uppercase">UBS Cafe</div>
            <div>
                <div className="card mt-2 border-0 py-3">
                    <div className="card mt-2 border-0" style={{backgroundColor: "#EBFFE9"}}>
                        <div className={"card-body py-5"}>
                            {/* <h1 className={"text-success"}>Successful Signup to {voucherdata[0].event_name}</h1> */}
                            <h1 className={"text-success"}>Signup Successful!</h1>
                                <div>You will receive an SMS containing a link to your voucher shortly.</div>
                        </div>
                    </div>
                </div>
                <div className={"mt-5"}>
                    {/* <button onClick={backToEventSignup} type="button" className="px-5 py-3 btn btn-primary" style={{letterSpacing: "1px"}}>BACK</button> */}
                    <button onClick={()=> navigate(-1)} type="button" className="px-5 py-3 btn btn-primary" style={{letterSpacing: "1px"}}>BACK</button>
                </div>
            </div>
        </div>
    )
}
